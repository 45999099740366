import React from 'react';
import TypingText from './TypingText';

const Message = React.memo(
  ({ message, index, delays, lastSentMsgIndex, messages = [] }) => {
    
    const receivedMessagesCount = messages.filter(msg => msg.type === 'received').length || 0;
    
    const isTypingMessage = true;

    const messageStyle = {
      borderRadius: '5px',
      padding: '15px',
      margin: '5px 0',
      maxWidth: '80%',
      alignSelf: message.type === 'sent' ? 'flex-end' : 'flex-start',
      backgroundColor: '#F0EFEA', // Example colors, adjust as needed
      color: message.type === 'sent' ? '#fff' : '#000', // Example text colors
    };

    const renderMessageContent = () => {
      if (isTypingMessage && message.text) {
        return (
          <TypingText
            text={message.text} // Replace 'User' with dynamic user data if available
            style={{ fontWeight: 'bold', fontSize: '19px', color: '#000', textAlign: "left" }} // Example styles, adjust as needed
            delay={20}
            key={Date.now()}
            last={true}
          />
        );
      } else {
        return (
          <div style={messageStyle}>
            <p style={{ margin: 0 }}>
              {message.text} {/* Replace 'User' with dynamic user data if available */}
            </p>
          </div>
        );
      }
    };
    return <div key={index}>{renderMessageContent()}</div>;
  },
  (prevProps, nextProps) => (
    prevProps.message.text === nextProps.message.text &&
    prevProps.message.type === nextProps.message.type &&
    prevProps.delays === nextProps.delays
  )
);

export default Message;
