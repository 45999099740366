import React, { useState } from "react";
import Message from "./Message";
import AuthStyles from "./auth-style";
import { styles } from "./chat";
import InputComponent from "./ChatInput";
import AvatarCtn from "./AvatarCtn";
import Button from "./Button";

const Chat = ({
  colors,
  messages,
  placeholder,
  submitValue,
  textValue,
  setTextValue,
  next
}) => {
  const [code, setCode] = useState("");

  const lastReceivedMessage =
    messages.filter((msg) => msg.type === "received").slice(-1)[0] || {};

  const onSend = () => {
    submitValue(textValue || code);
    setTextValue("");
    setCode("");
  };

  const onType = (text) => {
    setTextValue(text);
  };

  const authStyles = AuthStyles(colors);

  return (
    <div className="container">
      <div
        style={{
          paddingLeft: 10,
          paddingRight: 10,
        }}>
        <AvatarCtn
          styles={{
            ...authStyles,
            avatar: {
              ...authStyles.avatar,
              height: 300,
              marginTop: -50,
            },
          }}
          animated={"fadeIn"}
        />

        <div style={styles.container}>
          <div style={{ ...styles.messageContainer }}>
            <Message message={lastReceivedMessage} colors={colors} />
          </div>
          <div style={styles.inputContainer}>
            <InputComponent
              message={lastReceivedMessage}
              textValue={textValue}
              onType={onType}
              onSend={onSend}
              code={code}
              submitValue={submitValue}
              setCode={setCode}
              placeholder={placeholder}
              colors={colors}
            />
          </div>
          {textValue !== "stop-continue-btn" ? <Button
            label={"Continuer"}
            onPress={() => next()}
            color="black"
            style={{ backgroundColor: "#F0EFEA", marginTop: 30, width: Math.min(300, window.innerWidth*0.8), padding: 20, borderRadius: 10 }}
            colors={colors}
          /> : null}
        </div>
      </div>
    </div>
  );
};

export default Chat;
