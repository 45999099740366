import React, { useState } from "react";
import styles from './button';

/**
 * A custom button component that handles different styles and functionalities.
 *
 * @param {object} props - The properties passed to the component.
 * @param {string} props.option - The option text to be displayed.
 * @param {function} props.onPress - The function to call when the button is pressed.
 * @param {function} props.onClick - The function to call when the button is clicked.
 * @param {string} props.label - The label text to be displayed.
 * @param {object} props.colors - The colors to style the button.
 * @param {object} props.style - The additional styles to apply to the button.
 * @param {boolean} props.isSelected - Whether the button is selected or not.
 * @param {string} props.icon - The icon to be displayed in the button.
 * @param {string} props.clickColor - The color to apply when the button is clicked.
 */

const Button = ({
  option,
  onPress,
  onClick,
  label,
  isSelected,
  style,
  icon,
  clickColor,
  colors,
  fonts
}) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelect = (option) => {
    setSelectedOption(option);
    if (onPress) onPress(option);
  };

  const handleClick = () => {
    if (onClick) onClick();
  };

  const getBackgroundColor = () => {
    if (isSelected !== undefined) {
      return isSelected ? (clickColor || colors.light) : colors.beige;
    }
    return selectedOption ? colors.dark : colors.beige;
  };

  const backgroundColor = style?.backgroundColor || getBackgroundColor();

  const buttonStyles = {
    ...styles.button,
    backgroundColor,
    ...style,
    ...(icon ? styles.buttonWithIcon : {}),
    ...(isSelected && clickColor ? { backgroundColor: clickColor } : {}),
    ...{ fontWeight: 'bold', fontSize: '15px', color: '#000' }
  };

  const textColor = (backgroundColor === colors.light || backgroundColor === colors.beige)
    ? colors.dark
    : colors.beige;

  const textStyles = {
    ...styles.text,
    color: textColor,
    ...style?.buttonText,
    ...(icon ? styles.textWithIcon : {}),
  };

  return (
    <button
      onClick={() => {
        handleSelect(option);
        handleClick();
      }}
      style={buttonStyles}
    >
      {label || option}
    </button>
  );
};

export default Button;
