import React, { useRef, useEffect, useState } from 'react';

/**
 * TypingText displays text with a typing effect and optional blinking cursor.
 * 
 * @param {Object} props The component props.
 * @param {string} props.text The text to display with typing effect.
 * @param {Object} props.style Custom styles for the text.
 * @param {number} props.delay Initial delay before typing starts (in ms).
 * @param {Object} props.colors Color scheme used for styling.
 * @param {boolean} [props.last=false] Whether this is the last TypingText component in sequence.
 * @param {boolean} [props.isTitle=false] Whether the text is a title (affects styling).
 * @returns {JSX.Element|null} A view with typing text animation or null if no text.
 */
const TypingText = ({ text, style, delay, last = false, isTitle = false }) => {
  const [displayedText, setDisplayedText] = useState("");
  const [cursorVisible, setCursorVisible] = useState(false);
  const typingRef = useRef({ typing: false });
  const scrollRef = useRef(null);

  const startBlinking = (initialDelay) => {
    let blinkDelay = initialDelay;
    const blink = () => {
      setTimeout(() => {
        if (typingRef.current.typing) {
          setCursorVisible(prev => !prev);
        }
        if (blinkDelay <= 1500) {
          blinkDelay += 300;
          blink();
        } else {
          setCursorVisible(false);
        }
      }, 300);
    };
    blink();
  };

  useEffect(() => {
    let charIndex = 0;
    let intervalId;
    let typingRefCurrent = typingRef.current;
  
    const startTyping = () => {
      if (!typingRefCurrent.typing) {
        setCursorVisible(true);
        typingRefCurrent.typing = true;
        intervalId = setInterval(() => {
          if (charIndex+1 < text.length) {
            if (text[charIndex]) {
              setDisplayedText(prev => prev.replaceAll('|', '') + text[charIndex] + '|');
              charIndex++;
            }
          } else {
            clearInterval(intervalId);
            typingRefCurrent.typing = false;
            setDisplayedText(prev => prev.replaceAll('|', ''));
            if (!last) setCursorVisible(false);
            if (last) startBlinking(0);
          }
        }, 10); // Adjusted typing speed
      }
    };
  
    const timeoutId = setTimeout(startTyping, delay);
  
    return () => {
      clearTimeout(timeoutId);
      if (intervalId) clearInterval(intervalId);
      typingRefCurrent.typing = false;
      setDisplayedText('');
      setCursorVisible(false);
    };
  }, [text, delay, last]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [displayedText]);

  if (!cursorVisible && displayedText.length === 0) {
    return null;
  }


  return (
    <div
      style={isTitle ? styles.titleContainer : { ...styles.textContainer, backgroundColor: "#F0EFEA" }}
      ref={scrollRef}
    >
      <div style={styles.row}>
        <span style={style}>
          {displayedText.replaceAll('|', cursorVisible ? '|' : '')}
        </span>
      </div>
    </div>
  );
};

const styles = {
  titleContainer: {
    alignSelf: 'flex-start',
    borderRadius: 5,
    marginVertical: 5,
  },
  textContainer: {
    alignSelf: 'flex-start',
    borderRadius: 5,
    padding: 15,
    maxHeight: 250,
    marginVertical: 5,
    maxWidth: '80%',
    overflow: 'auto',  // Added overflow for scroll
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
};

export default TypingText;
