import logo from './logo.svg';
import './App.css';
import Create from './chat/Create';
function App() {
  return (
    <div className="App">
      <Create/>
    </div>
  );
}

export default App;
